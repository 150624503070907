import { ArchivesIcon, CommunicationIcon, ReturnTaskIcon } from '@app/components/Icons';
import BackupsIcon from '@app/components/Icons/BackupsIcon';
import CreditAndChargesIcon from '@app/components/Icons/CreditAndChargesIcon';
import Dashboard from '@app/components/Icons/Dashboard';
import DealsIcon from '@app/components/Icons/DealsIcon';
import ItemsMenuIcon from '@app/components/Icons/ItemsMenuIcon';
import OutgoingIcon from '@app/components/Icons/OutgoingIcon';
import PaymentIcon from '@app/components/Icons/PaymentIcon';
import ReceiverTerminalIcon from '@app/components/Icons/ReceiverTerminalIcon';
import ReceivingIcon from '@app/components/Icons/ReceivingIcon';
import RequestIcon from '@app/components/Icons/RequestIcon';
import Serials from '@app/components/Icons/Serials';
import TrackingNumbers from '@app/components/Icons/TrackingNumbers';
import Users from '@app/components/Icons/Users';
import WarehouseIcon from '@app/components/Icons/WarehouseIcon';
import { Permission } from '@app/pages/users/types';
import React from 'react';

interface AdditionalMainMenuItem {
  link: string;
  permission?: Permission;
}

export interface MainMenuItem {
  id: string;
  name: string;
  link?: string;
  icon?: JSX.Element;
  add?: AdditionalMainMenuItem;
  permission?: Permission;
  items?: MainMenuItem[];
  itemsLinks?: string[];
  divider?: boolean;
  href?: string;
}

const startYear = 2023;
const endYear = new Date().getFullYear() - 1;
const range = Array.from({ length: endYear - startYear + 1 }, (_, i) => i + startYear);
const { protocol, host } = document.location;
let formattedHost = host;
if (host.toLowerCase().startsWith('www.')) {
  formattedHost = host.slice(4);
}

export const mainMenuItems = (isEmployee: boolean): MainMenuItem[] => [
  {
    id: 'dashboard',
    name: 'Dashboard',
    link: '/dashboard',
    icon: <Dashboard />,
  },
  {
    id: 'deals',
    name: 'Deals',
    href: 'https://sites.google.com/view/pointsmakerdeals/home',
    icon: <DealsIcon />,
  },
  {
    id: 'items',
    name: 'Items',
    link: '/items',
    icon: <ItemsMenuIcon />,
    permission: 'can_item_list',
    add: {
      link: '/items/create',
      permission: 'can_item_create',
    },
  },
  {
    id: 'serials',
    name: 'Serials',
    link: '/serials',
    icon: <Serials />,
    permission: 'can_serial_list',
  },
  {
    id: 'receiving',
    name: 'Receiving',
    icon: <ReceivingIcon />,
    link: '/receiving',
    add: {
      link: '/receiving/create',
      permission: 'can_receiving_create',
    },
    // TODO BT-3717 Temporary hidden till the next release
    // itemsLinks: ['/receiving', '/receiving/create'],
    // items: [
    //   {
    //     id: 'receivingList',
    //     name: 'Receiving List',
    //     link: '/receiving',
    //     permission: 'can_receiving_list',
    //     add: {
    //       link: '/receiving/create',
    //       permission: 'can_receiving_create',
    //     },
    //   },
    // ],
  },
  {
    id: 'outgoing',
    name: 'Outgoing',
    link: '/outgoings',
    icon: <OutgoingIcon />,
    permission: 'can_invoice_list',
    add: {
      link: '/outgoings/create',
      // todo there is no such permission yet
      // permission: 'can_invoice_create',
    },
  },
  {
    id: 'requestPayment',
    name: 'Request Payment',
    link: '/payment/balance',
    icon: <RequestIcon />,
    permission: 'can_payment_viewOwn',
  },
  {
    id: 'payments',
    name: 'Payments',
    link: '/payments',
    icon: <PaymentIcon />,
    permission: 'can_payment_list',
    add: {
      link: '/payments/create',
      permission: 'can_payment_create',
    },
  },
  {
    id: 'creditsCharges',
    name: 'Credits and Charges',
    icon: <CreditAndChargesIcon />,
    link: '/credit-and-charges',
    add: {
      link: '/credit-and-charges/create',
      permission: 'can_creditCharge_create',
    },
    // TODO BT-3717 Temporary hidden till the next release
    // itemsLinks: ['/credit-and-charges', '/credit-and-charges/create'],
    // items: [
    //   {
    //     id: 'creditsChargesList',
    //     name: 'Credits and Charges',
    //     link: '/credit-and-charges',
    //     permission: 'can_creditCharge_list',
    //     add: {
    //       link: '/credit-and-charges/create',
    //       permission: 'can_creditCharge_create',
    //     },
    //   },
    //   {
    //     id: 'rewards',
    //     name: 'Rewards',
    //     link: '/rewards',
    //   },
    // ],
  },
  {
    id: 'trackingNumbers',
    name: 'Tracking Numbers',
    link: '/tracking-numbers',
    icon: <TrackingNumbers />,
    permission: 'can_tracking_view',
  },
  {
    id: 'warehouses',
    name: 'Warehouses',
    link: '/warehouses',
    icon: <WarehouseIcon />,
    permission: 'can_warehouse_list',
  },
  isEmployee
    ? {
        id: 'communication',
        name: 'Communication',
        icon: <CommunicationIcon />,
        // TODO: BT-4225 Add permissions to tickets when backend will ready
        itemsLinks: ['/tickets', '/tickets/create'],
        items: [
          {
            id: 'tickets',
            name: 'Tickets',
            link: '/tickets',
            add: {
              link: '/tickets/create',
            },
          },
        ],
      }
    : {
        id: 'tickets',
        name: 'Tickets',
        link: '/tickets',
        icon: <CommunicationIcon />,
        add: {
          link: '/tickets/create',
        },
      },
  {
    id: 'returns',
    name: 'Return Tasks',
    link: '/returns',
    icon: <ReturnTaskIcon />,
    permission: 'can_returnTask_list',
    add: {
      link: '/returns/create',
      permission: 'can_returnTask_create',
    },
  },
  {
    id: 'divider1',
    name: '',
    divider: true,
    permission: 'can_receipt_create',
  },
  {
    id: 'receiverTerminal',
    name: 'Receiver Terminal',
    link: '/receiver-terminal',
    icon: <ReceiverTerminalIcon />,
    permission: 'can_receipt_create',
  },
  {
    id: 'divider2',
    name: '',
    divider: true,
    permission: 'can_user_list',
  },
  {
    id: 'users',
    name: 'Users',
    icon: <Users />,
    itemsLinks: ['/users', '/users/create'],
    items: [
      {
        id: 'usersList',
        name: 'Users List',
        link: '/users',
        permission: 'can_user_list',
        add: {
          link: '/users/create',
          permission: 'can_user_create',
        },
      },
    ],
  },
  {
    id: 'divider3',
    name: '',
    divider: true,
    permission: 'can_user_list',
  },
  {
    id: 'backup',
    name: 'Backup',
    icon: <BackupsIcon />,
    link: '/backup',
    permission: 'can_backup_view',
  },
  {
    id: 'archives',
    name: 'Archives',
    icon: <ArchivesIcon />,
    itemsLinks: ['/archives'],
    items: range.map((year) => ({
      id: year.toString(),
      name: year.toString(),
      href: `${protocol}//${year}.${formattedHost}`,
    })),
  },
];
