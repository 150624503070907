import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const SellercloudIcon = ({ ...props }: SvgIconProps): JSX.Element => (
  <SvgIcon {...props} viewBox="0 0 18 18">
    <g clipPath="url(#clip0_1813_168736)">
      <path
        d="M4.3204 6.20351C3.75682 6.19312 3.19682 6.29516 2.67312 6.50365C2.14942 6.71215 1.67252 7.02292 1.27028 7.41781C0.86805 7.8127 0.548546 8.28379 0.330441 8.80356C0.112335 9.32333 0 9.88135 0 10.445C0 11.0087 0.112335 11.5667 0.330441 12.0865C0.548546 12.6063 0.86805 13.0774 1.27028 13.4723C1.67252 13.8671 2.14942 14.1779 2.67312 14.3864C3.19682 14.5949 3.75682 14.6969 4.3204 14.6866H10.0113C10.0113 14.6866 11.5247 14.7514 12.0273 13.7232C12.9158 11.8987 9.21784 6.19919 4.3204 6.20351Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.30987 7.40352L4.29829 7.40331C3.89413 7.39586 3.49253 7.46903 3.11697 7.61855C2.74141 7.76806 2.39941 7.99093 2.11096 8.27412C1.82251 8.5573 1.59338 8.89514 1.43697 9.26788C1.28056 9.64062 1.2 10.0408 1.2 10.445C1.2 10.8493 1.28056 11.2494 1.43697 11.6222C1.59338 11.9949 1.82251 12.3328 2.11096 12.6159C2.39941 12.8991 2.74141 13.122 3.11697 13.2715C3.49253 13.421 3.89413 13.4942 4.29829 13.4868L4.30934 13.4866H10.037L10.0545 13.4873C10.056 13.4873 10.0581 13.4873 10.0608 13.4874C10.0635 13.4874 10.0668 13.4874 10.0706 13.4873C10.0916 13.4872 10.1281 13.4865 10.1757 13.4833C10.2741 13.4767 10.4033 13.461 10.5348 13.4275C10.8311 13.3521 10.9226 13.2487 10.9483 13.1979C10.9483 13.1979 10.9484 13.1972 10.9487 13.1956C10.9546 13.1672 11.0161 12.8703 10.7638 12.2014C10.5229 11.5626 10.071 10.7987 9.43497 10.0614C8.14554 8.56686 6.30105 7.40176 4.32145 7.40351L4.30987 7.40352ZM12.0273 13.7232C11.5247 14.7514 10.0113 14.6866 10.0113 14.6866H4.3204C3.75682 14.6969 3.19682 14.5949 2.67312 14.3864C2.14942 14.1779 1.67252 13.8671 1.27028 13.4723C0.86805 13.0774 0.548546 12.6063 0.330441 12.0865C0.112335 11.5667 0 11.0087 0 10.445C0 9.88135 0.112335 9.32333 0.330441 8.80356C0.548546 8.28379 0.86805 7.8127 1.27028 7.41781C1.67252 7.02292 2.14942 6.71215 2.67312 6.50365C3.19682 6.29516 3.75682 6.19312 4.3204 6.20351C9.21784 6.19919 12.9158 11.8987 12.0273 13.7232Z"
        fill="#212B36"
      />
      <path
        d="M14.926 8.928C14.926 7.78878 14.5882 6.67514 13.9553 5.72792C13.3224 4.78069 12.4228 4.04242 11.3703 3.60646C10.3178 3.17049 9.15962 3.05643 8.04229 3.27868C6.92496 3.50093 5.89862 4.04952 5.09307 4.85507C4.28752 5.66062 3.73894 6.68695 3.51669 7.80428C3.29443 8.92161 3.4085 10.0798 3.84446 11.1323C4.28042 12.1848 5.0187 13.0843 5.96592 13.7173C6.91315 14.3502 8.02679 14.688 9.16601 14.688H13.7826C13.7826 14.688 14.926 10.4242 14.926 8.928Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8523 13.488C12.9495 13.0997 13.067 12.6165 13.1844 12.1022C13.4784 10.8133 13.726 9.51415 13.726 8.928C13.726 8.02612 13.4586 7.14449 12.9575 6.3946C12.4565 5.64471 11.7443 5.06025 10.911 4.71511C10.0778 4.36998 9.16095 4.27967 8.2764 4.45562C7.39184 4.63157 6.57933 5.06587 5.9416 5.70359C5.30387 6.34132 4.86958 7.15384 4.69363 8.03839C4.51768 8.92294 4.60798 9.83981 4.95312 10.673C5.29825 11.5063 5.88272 12.2184 6.63261 12.7195C7.3825 13.2206 8.26413 13.488 9.16601 13.488H12.8523ZM13.9553 5.72792C14.5882 6.67514 14.926 7.78878 14.926 8.928C14.926 10.4242 13.7826 14.688 13.7826 14.688H9.16601C8.02679 14.688 6.91315 14.3502 5.96592 13.7173C5.0187 13.0843 4.28042 12.1848 3.84446 11.1323C3.4085 10.0798 3.29443 8.92161 3.51669 7.80428C3.73894 6.68695 4.28752 5.66062 5.09307 4.85507C5.89862 4.04952 6.92496 3.50093 8.04229 3.27868C9.15962 3.05643 10.3178 3.17049 11.3703 3.60646C12.4228 4.04242 13.3224 4.78069 13.9553 5.72792Z"
        fill="#212B36"
      />
      <path
        d="M14.3918 7.37857C14.8717 7.37857 15.3469 7.4731 15.7904 7.65677C16.2338 7.84044 16.6367 8.10964 16.976 8.44901C17.3154 8.78839 17.5846 9.19128 17.7683 9.63469C17.9519 10.0781 18.0465 10.5533 18.0465 11.0333C18.0465 11.5132 17.9519 11.9885 17.7683 12.4319C17.5846 12.8753 17.3154 13.2782 16.976 13.6176C16.6367 13.9569 16.2338 14.2261 15.7904 14.4098C15.3469 14.5935 14.8717 14.688 14.3918 14.688H9.48712C9.48712 14.688 8.20696 14.688 7.75912 13.8571C6.91528 12.3207 10.1711 7.37569 14.3918 7.37857Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3918 8.57857H14.3909C12.7497 8.57745 11.1844 9.55426 10.0836 10.826C9.54202 11.4516 9.16486 12.0919 8.97081 12.6142C8.80661 13.0561 8.82563 13.2591 8.82644 13.2979C8.85249 13.3214 8.92808 13.3771 9.11054 13.4275C9.21764 13.457 9.32268 13.4731 9.40211 13.4812C9.44049 13.4852 9.46947 13.4869 9.48545 13.4876L9.49633 13.488H14.3918C14.7141 13.488 15.0333 13.4245 15.3311 13.3012C15.629 13.1778 15.8996 12.997 16.1275 12.769C16.3554 12.5411 16.5363 12.2705 16.6596 11.9727C16.783 11.6749 16.8465 11.3557 16.8465 11.0333C16.8465 10.7109 16.783 10.3917 16.6596 10.0939C16.5363 9.79609 16.3554 9.52549 16.1275 9.29754C15.8996 9.0696 15.629 8.88879 15.3311 8.76543C15.0333 8.64207 14.7141 8.57857 14.3918 8.57857ZM7.75912 13.8571C8.20696 14.688 9.48712 14.688 9.48712 14.688H14.3918C14.8717 14.688 15.3469 14.5935 15.7904 14.4098C16.2338 14.2261 16.6367 13.9569 16.976 13.6176C17.3154 13.2782 17.5846 12.8753 17.7683 12.4319C17.9519 11.9885 18.0465 11.5132 18.0465 11.0333C18.0465 10.5533 17.9519 10.0781 17.7683 9.63469C17.5846 9.19128 17.3154 8.78839 16.976 8.44902C16.6367 8.10964 16.2338 7.84044 15.7904 7.65677C15.3469 7.4731 14.8717 7.37857 14.3918 7.37857C10.1711 7.37569 6.91528 12.3207 7.75912 13.8571Z"
        fill="#212B36"
      />
    </g>
    <defs>
      <clipPath id="clip0_1813_168736">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default SellercloudIcon;
