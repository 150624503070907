import InfoMenu from '@app/components/DefaultPageHeader/InfoMenu/InfoMenu';
import { useLogoutDialog } from '@app/components/DefaultPageHeader/LogoutDialog/useLogoutDialog';
import ProfileButton from '@app/components/DefaultPageHeader/ProfileButton';
import {
  divider,
  iconBox,
  indicator,
  menuItem,
  name,
  profile,
  roleAndId,
  signOutBox,
  signOutButton,
  viewProfile,
} from '@app/components/DefaultPageHeader/ProfileMenu/ProfileMenu.sx';
import { SellercloudIcon } from '@app/components/Icons';
import HelpCircle from '@app/components/Icons/HelpCircle';
import LogoutIcon from '@app/components/Icons/LogoutIcon';
import UserIcon from '@app/components/Icons/UserIcon';
import Tooltip from '@app/components/Tooltip';
import WarningIndicator from '@app/components/WarningIndicator';
import { verifyPhone } from '@app/constants/profile';
import { userRole } from '@app/constants/user-roles';
import { useCurrentUser } from '@app/contexts/currentUser';
import { UserRoles } from '@app/pages/users/types';
import { useMenuToggle } from '@app/utils/hooks/useMenuToggle';
import { rem } from '@app/utils/sx/functions';
import { Badge, Box, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styles from './DefaultPageHeader.module.scss';
import LogoutDialog from './LogoutDialog';

interface Props {
  newLogsCount: number;
}
const MobileMenu = ({ newLogsCount }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { firstName, lastName, role, userId, phoneVerified } = useCurrentUser() || {};
  const unverifiedPhone = userId && !phoneVerified;
  const { isLoading, isOpen, openDialog, closeDialog, forgetDevice, setForgetDevice } = useLogoutDialog();
  const mobileMenuId = 'header-menu-mobile';
  const isEmployee = role === UserRoles.Admin || role === UserRoles.Manager;

  const { openMenu: handleMenuOpen, closeMenu: handleMenuClose, anchorEl, open } = useMenuToggle();
  const { openMenu, closeMenu, anchorEl: el } = useMenuToggle(handleMenuClose);

  return (
    <>
      <ProfileButton open={null} openMenu={handleMenuOpen} />

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleMenuClose}
        classes={{ root: styles.Modal }}
        className="HeaderMenuMobile"
        sx={{
          '.MuiPaper-root': {
            left: `${rem(12)} !important`,
            width: '100%',
            maxWidth: `calc(100% - ${rem(24)})`,
          },
        }}
      >
        <MenuItem sx={menuItem} disabled disableRipple>
          <Box sx={profile}>
            <Box sx={name}>
              {firstName} {lastName}
            </Box>
            <Box sx={roleAndId}>
              <Typography>{`ID${userId}`}</Typography>
              <Typography>{userRole[role]}</Typography>
            </Box>
          </Box>
        </MenuItem>

        <Divider sx={divider} />

        <MenuItem component={Link} to={'/profile'} onClick={handleMenuClose} disableRipple>
          <Box sx={{ display: 'flex', flex: 1, justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={viewProfile}>
              <UserIcon sx={iconBox} />
              View my profile
            </Box>

            {unverifiedPhone && (
              <Tooltip placement="bottom-end" title={t(verifyPhone)}>
                <Box sx={{ pl: '20px' }}>
                  <WarningIndicator sx={{ ...indicator, position: 'static' }} />
                </Box>
              </Tooltip>
            )}
          </Box>
        </MenuItem>

        {isEmployee && (
          <MenuItem component={Link} to={'/sellercloud-logs'} onClick={handleMenuClose} disableRipple>
            <IconButton sx={iconBox} size="small" color="inherit">
              {newLogsCount ? (
                <Badge
                  badgeContent={newLogsCount}
                  color="error"
                  sx={{ '.MuiBadge-badge': { backgroundColor: 'secondary.negative', m: '4px 6px 0 0' } }}
                >
                  <SellercloudIcon />
                </Badge>
              ) : (
                <SellercloudIcon />
              )}
            </IconButton>
            Sellercloud logs
          </MenuItem>
        )}

        {/* TODO: temporary hide settings on mobile */}
        {/* <MenuItem>
          <IconButton className={styles.SettingsIcon} size="small" color="inherit">
            <Settings />
          </IconButton>
          Settings
        </MenuItem> */}

        <MenuItem onClick={openMenu}>
          <IconButton sx={iconBox} size="small" color="inherit">
            <HelpCircle />
          </IconButton>
          Info
        </MenuItem>

        <MenuItem sx={signOutBox} onClick={handleMenuClose} disableRipple>
          <Button sx={signOutButton} variant="outlined" color="inherit" startIcon={<LogoutIcon />} onClick={openDialog}>
            Sign out
          </Button>
        </MenuItem>
      </Menu>

      <LogoutDialog
        open={isOpen}
        onClose={closeDialog}
        forgetDevice={forgetDevice}
        setForgetDevice={setForgetDevice}
        isLoading={isLoading}
      />

      <InfoMenu
        id={'info-menu-mobile'}
        anchorEl={el}
        closeMenu={closeMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      />
    </>
  );
};

export default MobileMenu;
