import FullPageSpinner from '@app/components/FullPageSpinner';
import PrivateRoute from '@app/components/Routes/PrivateRoute';
import CurrentUserProvider from '@app/contexts/currentUser';
import MainLayout from '@app/layouts/MainLayout';
import NoMatch from '@app/pages/NoMatch';
import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router';
import { Redirect } from 'react-router-dom';

const Styleguide = lazy(() => import('@app/pages/Styleguide'));
const Home = lazy(() => import('@app/pages/Home'));
const Profile = lazy(() => import('@app/pages/Profile'));
const CreateUser = lazy(() => import('@app/pages/users/CreateUser'));
const UsersList = lazy(() => import('@app/pages/users/UsersList'));
const UserDetails = lazy(() => import('@app/pages/users/UserDetails'));
const WarehousesList = lazy(() => import('@app/pages/warehouses/WarehousesList'));
const CreateWarehouse = lazy(() => import('@app/pages/warehouses/CreateWarehouse'));
const WarehouseDetails = lazy(() => import('@app/pages/warehouses/WarehouseDetails'));
const Items = lazy(() => import('@app/pages/items'));
const AddAdjustment = lazy(() => import('@app/pages/items/AddAdjustment'));
const CreateItem = lazy(() => import('@app/pages/items/CreateItem'));
const ItemDetails = lazy(() => import('@app/pages/items/ItemDetails'));
const TermsAndConditions = lazy(() => import('@app/pages/TermsAndConditions'));
const Serials = lazy(() => import('@app/pages/serials'));
const AddSerial = lazy(() => import('@app/pages/serials/AddSerial'));
const TrackingNumbers = lazy(() => import('@app/pages/TrackingNumbers'));
const AddTracking = lazy(() => import('@app/pages/TrackingNumbers/AddTracking'));
const ReceiverTerminal = lazy(() => import('@app/pages/ReceiverTerminal'));
const ReceiverTerminalDetails = lazy(
  () => import('@app/pages/ReceiverTerminal/CreateReceiptSteps/SetDetails/SetDetails')
);
const ReceiverTerminalTrackings = lazy(() => import('@app/pages/ReceiverTerminal/CreateReceiptSteps/AddTrackings'));
const ReceiverTerminalBuyer = lazy(() => import('@app/pages/ReceiverTerminal/CreateReceiptSteps/AddBuyer'));
const ReceiverTerminalItems = lazy(() => import('@app/pages/ReceiverTerminal/CreateReceiptSteps/AddItems'));
const ReceiverTerminalReview = lazy(() => import('@app/pages/ReceiverTerminal/CreateReceiptSteps/Review'));
const ReceiverTerminalCreated = lazy(() => import('@app/pages/ReceiverTerminal/CreateReceiptSteps/CreatedMessage'));
const ReceiverTerminalSearch = lazy(() => import('@app/pages/ReceiverTerminal/SearchTracking'));
const ReceivingList = lazy(() => import('@app/pages/receiving/ReceivingList'));
const ReceiptDetails = lazy(() => import('@app/pages/receiving/ReceiptDetails'));
const RequestPaymentPrototype = lazy(
  () => import('@app/pages/RequestPayment/RequestPaymentPrototype/RequestPaymentPrototype')
);
const RequestCheckPrototype = lazy(
  () => import('@app/pages/RequestPayment/RequestCheckPrototype/RequestCheckPrototype')
);
const SearchResults = lazy(() => import('@app/pages/SearchResults'));
const Balance = lazy(() => import('@app/pages/payments/Balance'));
const PaymentDetails = lazy(() => import('@app/pages/payments/PaymentDetails'));
const PaymentsList = lazy(() => import('@app/pages/payments/PaymentsList'));
const RequestPayment = lazy(() => import('@app/pages/payments/RequestPayment'));
const Outgoings = lazy(() => import('@app/pages/Outgoings'));
const CreateOutgoing = lazy(() => import('@app/pages/Outgoings/CreateOutgoing/CreateOutgoing'));
const CreditAndChargesList = lazy(() => import('@app/pages/CreditAndCharges/CreditAndChargesList'));
const CreditDetails = lazy(() => import('@app/pages/CreditAndCharges/CreditDetails'));
const RewardsList = lazy(() => import('@app/pages/CreditAndCharges/RewardsList'));
const BackupsList = lazy(() => import('@app/pages/Backups/BackupsList'));
const TicketList = lazy(() => import('@app/pages/tickets/TicketsList'));
const CreateTicket = lazy(() => import('@app/pages/tickets/CreateTicket'));
const TicketDetails = lazy(() => import('@app/pages/tickets/TicketDetails'));
const SellercloudLogs = lazy(() => import('@app/pages/sellercloud-logs'));
const ReturnTasksList = lazy(() => import('@app/pages/ReturnTasks/ReturnTasksList'));

const MainLayoutRoutes = (): JSX.Element => (
  <CurrentUserProvider>
    <MainLayout>
      <Suspense fallback={<FullPageSpinner />}>
        <Switch>
          <PrivateRoute path="/styleguide">
            <Styleguide />
          </PrivateRoute>
          <Route exact path="/">
            <Redirect to="/dashboard" />
          </Route>
          <PrivateRoute path="/dashboard">
            <Home />
          </PrivateRoute>
          <PrivateRoute path="/profile">
            <Profile />
          </PrivateRoute>
          <PrivateRoute exact path="/users" permission="can_user_list">
            <UsersList />
          </PrivateRoute>
          <PrivateRoute
            path="/users/create"
            permission="can_user_create"
            backPermission="can_user_list"
            backUrl="/users"
          >
            <CreateUser />
          </PrivateRoute>
          <PrivateRoute path="/users/:id" permission="can_user_view">
            <UserDetails />
          </PrivateRoute>
          <PrivateRoute exact path="/warehouses" permission="can_warehouse_list">
            <WarehousesList />
          </PrivateRoute>
          <PrivateRoute
            path="/warehouses/create"
            permission="can_warehouse_create"
            backPermission="can_warehouse_list"
            backUrl="/warehouses"
          >
            <CreateWarehouse />
          </PrivateRoute>
          <PrivateRoute path="/warehouses/:id" permission="can_warehouse_view">
            <WarehouseDetails />
          </PrivateRoute>
          <PrivateRoute exact path="/items" permission="can_item_list">
            <Redirect to="/items/list" />
          </PrivateRoute>
          <PrivateRoute path={['/items/list', '/items/adjustments']} permission="can_item_list">
            <Items />
          </PrivateRoute>
          <PrivateRoute
            path="/items/create"
            permission="can_item_create"
            backPermission="can_item_list"
            backUrl="/items/list"
          >
            <CreateItem />
          </PrivateRoute>
          <PrivateRoute path="/items/add-adjustment" permission="can_item_create">
            <AddAdjustment />
          </PrivateRoute>
          <PrivateRoute path="/items/:id" permission="can_item_view">
            <ItemDetails />
          </PrivateRoute>
          <PrivateRoute exact path="/serials" permission="can_serial_list">
            <Redirect to="/serials/receipts" />
          </PrivateRoute>
          <PrivateRoute path={['/serials/receipts', '/serials/boxes']} permission="can_serial_list" withDefaultFilter>
            <Serials />
          </PrivateRoute>
          <PrivateRoute
            path="/serials/create"
            permission="can_serial_create"
            backPermission="can_serial_list"
            backUrl="/serials"
          >
            <AddSerial />
          </PrivateRoute>
          <PrivateRoute path="/terms-and-conditions">
            <TermsAndConditions />
          </PrivateRoute>
          <PrivateRoute exact path="/tracking-numbers" permission="can_tracking_list" withDefaultFilter>
            <TrackingNumbers />
          </PrivateRoute>
          <PrivateRoute
            path="/tracking-numbers/create"
            permission="can_tracking_update"
            backPermission="can_tracking_list"
            backUrl="/tracking-numbers"
          >
            <AddTracking />
          </PrivateRoute>
          <PrivateRoute exact path="/receiver-terminal" permission="can_receipt_create">
            <ReceiverTerminal />
          </PrivateRoute>
          <PrivateRoute path="/receiver-terminal/details" permission="can_receipt_create">
            <ReceiverTerminalDetails />
          </PrivateRoute>
          <PrivateRoute path="/receiver-terminal/trackings" permission="can_receipt_create">
            <ReceiverTerminalTrackings />
          </PrivateRoute>
          <PrivateRoute path="/receiver-terminal/buyer" permission="can_receipt_create">
            <ReceiverTerminalBuyer />
          </PrivateRoute>
          <PrivateRoute path="/receiver-terminal/items" permission="can_receipt_create">
            <ReceiverTerminalItems />
          </PrivateRoute>
          <PrivateRoute path="/receiver-terminal/review" permission="can_receipt_create">
            <ReceiverTerminalReview />
          </PrivateRoute>
          <PrivateRoute path="/receiver-terminal/created" permission="can_receipt_create">
            <ReceiverTerminalCreated />
          </PrivateRoute>
          <PrivateRoute path="/receiver-terminal/search" permission="can_receipt_create">
            <ReceiverTerminalSearch />
          </PrivateRoute>
          <PrivateRoute exact path="/receiving" permission="can_receiving_list" withDefaultFilter>
            <ReceivingList />
          </PrivateRoute>
          <PrivateRoute
            path="/receiving/create"
            permission="can_receiving_create"
            backPermission="can_receiving_list"
            backUrl="/receiving"
          >
            <ReceiptDetails isCreating={true} />
          </PrivateRoute>
          <PrivateRoute path="/receiving/:id" permission="can_receiving_view">
            <ReceiptDetails />
          </PrivateRoute>
          <PrivateRoute path="/payment/balance" permission="can_payment_viewOwn">
            <Balance />
          </PrivateRoute>
          <PrivateRoute exact path="/payments" permission="can_payment_list" withDefaultFilter>
            <PaymentsList />
          </PrivateRoute>
          <PrivateRoute
            path="/payments/request-payment"
            permission="can_payment_viewOwn"
            backPermission="can_payment_list"
            backUrl="/payments"
          >
            <RequestPayment />
          </PrivateRoute>
          <PrivateRoute
            path="/payments/create"
            permission="can_payment_create"
            backPermission="can_payment_list"
            backUrl="/payments"
          >
            <PaymentDetails isCreating={true} />
          </PrivateRoute>
          <PrivateRoute path="/payments/:id" permission="can_payment_view">
            <PaymentDetails />
          </PrivateRoute>
          <PrivateRoute exact path="/credit-and-charges" permission="can_creditCharge_list" withDefaultFilter>
            <CreditAndChargesList />
          </PrivateRoute>
          <PrivateRoute
            path="/credit-and-charges/create"
            permission="can_creditCharge_create"
            backPermission="can_creditCharge_list"
            backUrl="/credit-and-charges"
          >
            <CreditDetails />
          </PrivateRoute>
          <PrivateRoute
            path="/credit-and-charges/:id"
            permission="can_creditCharge_view"
            backPermission="can_creditCharge_list"
            backUrl="/credit-and-charges"
          >
            <CreditDetails />
          </PrivateRoute>
          <PrivateRoute path="/rewards">
            <RewardsList />
          </PrivateRoute>
          <PrivateRoute exact path="/backup" permission="can_backup_view">
            <BackupsList />
          </PrivateRoute>
          {/* TODO: BT-4225 Add permissions to tickets routes when backend will ready */}
          <PrivateRoute exact path="/tickets" withDefaultFilter>
            <TicketList />
          </PrivateRoute>
          <PrivateRoute path="/tickets/create">
            <CreateTicket />
          </PrivateRoute>
          <PrivateRoute path="/tickets/:id">
            <TicketDetails />
          </PrivateRoute>

          {/* TODO remove this route after Receiver Terminal developed */}
          <PrivateRoute path="/request-check">
            <RequestCheckPrototype />
          </PrivateRoute>

          <PrivateRoute path="/request-payment">
            <RequestPaymentPrototype />
          </PrivateRoute>

          <PrivateRoute path="/search-results">
            <SearchResults />
          </PrivateRoute>

          <PrivateRoute exact path="/outgoings" permission="can_invoice_list">
            <Redirect to="/outgoings/list" />
          </PrivateRoute>
          <PrivateRoute
            path={['/outgoings/list', '/outgoings/customers']}
            permission="can_invoice_list"
            withDefaultFilter
          >
            <Outgoings />
          </PrivateRoute>
          <PrivateRoute path="/outgoings/create/:id?">
            <CreateOutgoing />
          </PrivateRoute>
          <PrivateRoute exact path="/returns" permission="can_returnTask_list" withDefaultFilter>
            <ReturnTasksList />
          </PrivateRoute>
          <PrivateRoute path="/returns/create" permission="can_returnTask_create">
            <Redirect to={{ pathname: '/returns', state: { backlink: '/returns', open: true } }} />
          </PrivateRoute>
          <PrivateRoute path="/sellercloud-logs">
            <SellercloudLogs />
          </PrivateRoute>

          <PrivateRoute>
            <NoMatch />
          </PrivateRoute>
        </Switch>
      </Suspense>
    </MainLayout>
  </CurrentUserProvider>
);

export default MainLayoutRoutes;
