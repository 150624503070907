import { rem } from '@app/utils/sx/functions';
import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const ArchivesIcon = ({ sx, ...props }: SvgIconProps): JSX.Element => (
  <SvgIcon
    sx={{
      fontSize: rem(18),
      ...sx,
    }}
    viewBox="0 0 18 18"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 3.89999V5.69999H16.5V3.89999H1.5ZM1 2.39999C0.447715 2.39999 0 2.84771 0 3.39999V6.19999C0 6.75228 0.447716 7.19999 1 7.19999H17C17.5523 7.19999 18 6.75228 18 6.19999V3.39999C18 2.84771 17.5523 2.39999 17 2.39999H1Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.19999 15.3H14.8C15.0761 15.3 15.3 15.0761 15.3 14.8V7.49999H2.69999V14.8C2.69999 15.0761 2.92384 15.3 3.19999 15.3ZM14.8 16.8C15.9046 16.8 16.8 15.9046 16.8 14.8V5.99999H1.19999V14.8C1.19999 15.9046 2.09542 16.8 3.19999 16.8H14.8Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.25 10.8C5.25 10.3858 5.58579 10.05 6 10.05H12C12.4142 10.05 12.75 10.3858 12.75 10.8C12.75 11.2142 12.4142 11.55 12 11.55H6C5.58579 11.55 5.25 11.2142 5.25 10.8Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default ArchivesIcon;
